import Laboratory from "services/laboratory.services";
import { IModule } from "dto/module.dto";
import { ILaboratory, ILaboratoryCongratulations } from "dto/laboratory.dto";
import { IUser, ITask } from "dto/user.dto";
import { laboratoryCongratulationsInit, laboratoryInit, moduleInit } from "initials";
import * as utils from "utils";
import { IPermission } from "dto/permission.dto";
import { IChallenger } from "dto/challenger.dto";

export const getLaboratories = async (data: ILaboratory[], user: IUser) => {
  const labs: ILaboratory[] = data?.map((laboratory) => ({ 
    ...laboratory, 
    key: laboratory?._id ?? "", 
    type: "laboratory", 
  }));
  const labsPrivate = labs?.filter((l) => (l.state !== "draft" && l.state !== "public"));
  const labsEnabled = getLaboratoriesEnabled(labsPrivate, user);
  try {
    const laboratories = await getLaboratoriesFiles(labsEnabled);
    return laboratories;
  } catch (error) {
    return labsEnabled;
  }
}

export const getLaboratoryByKey = async (key: string) => {
  try {
    const data = await Laboratory.getLaboratoryByName(key);
    if (!!data) {
      const laboratory: ILaboratory = { ...data, key, type: "laboratory" }
      return laboratory
    } else {
      const laboratoryByKey = await Laboratory.getLaboratoryByKey(key);
      const data = laboratoryByKey ?? laboratoryInit;
      const laboratory: ILaboratory = { ...data, type: "laboratory" }
      return laboratory;
    }
    // const data = await Laboratory.getLaboratoryByKey(key);
    // if (!!data) {
    //   const laboratory: ILaboratory = { ...data, key, type: "laboratory" }
    //   return laboratory
    // } else {
    //   const laboratoryByName = await Laboratory.getLaboratoryByName(key);
    //   const data = laboratoryByName ?? laboratoryInit;
    //   const laboratory: ILaboratory = { ...data, type: "laboratory" }
    //   return laboratory;
    // }    
  } catch (error) {
    return laboratoryInit;
  }
}
export const getLaboratoryFiles = async (laboratory: ILaboratory) => {
  try {
    const background_image_url = await utils.downloadFile(
      laboratory?.name,
      laboratory?.background_image ?? "",
    );
    const background_video_url = await utils.downloadFile(
      laboratory?.name,
      laboratory?.background_video ?? "",
    );
    return { 
      ...laboratory, 
      background_image_url, 
      background_video_url, 
    }
  } catch (error) {
    return laboratory;
  }
}
export const getLaboratoryEnabled = (laboratory: ILaboratory, user: IUser) => {
  if (laboratory?.state === "public" || laboratory?.state === "draft") {
    return laboratory;
  } else {
    const modules = utils.getModulesEnabled(laboratory, user);
    return { ...laboratory, modules };
  }
}

export const getLaboratoryProgress = (laboratory: ILaboratory, tasks: ITask[]) => {
  if (!!laboratory?.modules?.length) {
    const modules = laboratory.modules;
    const modulesAux: IModule[] = [];
    
    for (let i = 0; i < modules.length; i++) {
      const moduleProgress =  utils.getModuleProgress(laboratory?.name, modules[i], tasks).progress
      const tasksAux = tasks.filter((t) => (
        t.laboratory === laboratory?.name &&
        t.module === modules[i]?.name &&
        moduleProgress === modules[i]?.challenges?.length
      ));
      if (!!tasksAux?.length) modulesAux.push(modules[i]);
    }

    const total = modules?.length;
    const progress = modulesAux?.length;
    const percentage = progress / total;

    return { total, progress, percentage };
  } else return { total: 0, progress: 0, percentage: 0 };
}

export const getLaboratoryPublic = (laboratory: ILaboratory) => {
  try {
    const modulesWithQuestions = utils.getModulesWithQuestions(laboratory);
    return { ...laboratory, modules: modulesWithQuestions }
  } catch (error) {
    return laboratoryInit;
  }
}
export const getLaboratoryPrivate = (value: ILaboratory, user: IUser) => {
  try {
    const modulesEnabled = utils.getModulesEnabled(value, user);
    const laboratory: ILaboratory = { ...value, modules: modulesEnabled }
    if (!!laboratory?.name && !!laboratory?.modules?.length) {
      const modulesWithQuestions = utils.getModulesWithQuestions(laboratory);
      return { ...laboratory, modules: modulesWithQuestions }
    } else return laboratoryInit;
  } catch (error) {
    return laboratoryInit;
  }
}

export const getLaboratoryDraft = async (laboratory: ILaboratory, user: IUser) => {
  try {
    if (!!utils.getIsContentCreator(user)) {
      const laboratoryFiles = await utils.getLaboratoryFiles(laboratory);
      const modulesWithQuestions = utils.getModulesWithQuestions(laboratory);
      const modulesFiles = await utils.getModulesFiles(laboratory?.name, modulesWithQuestions);
      return { ...laboratoryFiles, modules: modulesFiles }
    } else return laboratoryInit;
  } catch (error) {
    return laboratoryInit;
  }
}
export const getLaboratoryPublicWithFiles = async (laboratory: ILaboratory) => {
  try {
    const laboratoryFiles = await utils.getLaboratoryFiles(laboratory);
    const modulesWithQuestions = utils.getModulesWithQuestions(laboratory);
    const modulesFiles = await utils.getModulesFiles(laboratory?.name, modulesWithQuestions);
    return { ...laboratoryFiles, modules: modulesFiles }
  } catch (error) {
    return laboratoryInit;
  }
}
export const getLaboratoryPrivateWithFiles = async (value: ILaboratory, user: IUser) => {
  try {
    const modulesEnabled = utils.getModulesEnabled(value, user);
    const laboratory: ILaboratory = { ...value, modules: modulesEnabled }
    if (!!laboratory?.name && !!laboratory?.modules?.length) {
      const laboratoryFiles = await utils.getLaboratoryFiles(laboratory);
      const modulesWithQuestions = utils.getModulesWithQuestions(laboratory);
      const modulesFiles = await utils.getModulesFiles(laboratory?.name, modulesWithQuestions);
      return { ...laboratoryFiles, modules: modulesFiles }
    } else return laboratoryInit;
  } catch (error) {
    return laboratoryInit;
  }
}

export const getLaboratoryRate = (laboratory: ILaboratory, tasks: ITask[]) => {
  let rate = 0;
  const modules = laboratory?.modules ?? [];
  for (let i = 0; i < modules?.length; i++) {
    rate = rate + utils.gatModuleRate(laboratory?.name, modules[i], tasks, true);
  }

  if (!!modules?.length) return Math.round(rate / modules.length);
  else return 0; 
}

export const getLaboratoryCongratulations = async (laboratory: ILaboratory) => {
  try {
    const congratulations: ILaboratoryCongratulations = {
      complete_image: await utils.downloadFile(
        laboratory?.name,
        laboratory?.congratulations?.complete_image,
        laboratoryCongratulationsInit?.complete_image
      ),
      complete_audio_fl: await utils.downloadFile(
        laboratory?.name, 
        laboratory?.congratulations?.complete_audio_fl,
        laboratoryCongratulationsInit?.complete_audio_fl,
      ),
      complete_audio_sl: await utils.downloadFile(
        laboratory?.name, 
        laboratory?.congratulations?.complete_audio_sl,
        laboratoryCongratulationsInit?.complete_audio_sl,
      ),
      complete_text_one: !!laboratory?.congratulations?.complete_text_one 
        ? laboratory.congratulations.complete_text_one
        : laboratoryCongratulationsInit?.complete_text_one,
      complete_text_two: !!laboratory?.congratulations?.complete_text_two 
        ? laboratory.congratulations.complete_text_two
        : laboratoryCongratulationsInit?.complete_text_two,
      incomplete_image: await utils.downloadFile(
        laboratory?.name, 
        laboratory?.congratulations?.incomplete_image,
        laboratoryCongratulationsInit?.incomplete_image,
      ),
      incomplete_audio_fl: await utils.downloadFile(
        laboratory?.name, 
        laboratory?.congratulations?.incomplete_audio_fl,
        laboratoryCongratulationsInit?.incomplete_audio_fl,
      ),
      incomplete_audio_sl: await utils.downloadFile(
        laboratory?.name, 
        laboratory?.congratulations?.incomplete_audio_fl,
        laboratoryCongratulationsInit?.incomplete_audio_fl,
      ),
      incomplete_text_one: !!laboratory?.congratulations?.incomplete_text_one 
        ? laboratory.congratulations.incomplete_text_one
        : laboratoryCongratulationsInit?.incomplete_text_one,
      incomplete_text_two: !!laboratory?.congratulations?.incomplete_text_two 
        ? laboratory.congratulations.incomplete_text_two
        : laboratoryCongratulationsInit?.incomplete_text_two,
    }
    return congratulations;
  } catch (error) {
    return laboratoryCongratulationsInit;
  }
}

export const getLaboratoryCompletedUsingProgress = (
  laboratory: ILaboratory, 
  moduleCurrent: IModule,
  challengerCurrent: IChallenger,
  tasks: ITask[],
) => {
  const modules = laboratory?.modules ?? [];
  const progress = utils.getLaboratoryProgress(laboratory, tasks).progress;
  return modules?.length === progress &&
    !!utils.getChallengerLast(moduleCurrent, challengerCurrent);
}

const getLaboratoriesFiles = async (laboratories: ILaboratory[]) => {
  try {
    const data: ILaboratory[] = [];
    for (let i = 0; i < laboratories?.length; i++) {
      const laboratory = await utils.getLaboratoryFiles(laboratories[i]);
      data.push(laboratory);
    }
    return data;
  } catch (error) {
    return laboratories ?? [];
  }
}
const getLaboratoriesEnabled = (labs: ILaboratory[], user: IUser) => {
  const permissions = user?.permissions ?? [];
  const laboratories = filterLaboratoriesInLaboratoriesLarger(
    labs, 
    convertPermissionsToLaboratories(permissions),
  );
  const data: ILaboratory[] = [];
  for (let i = 0; i < laboratories?.length; i++) {
    data.push(utils.getLaboratoryEnabled(laboratories[i], user));
  }
  return data;
}
const searchLaboratoryInLaboratories = (
  laboratory: ILaboratory,
  laboratories: ILaboratory[],
) => {
  return !!laboratories?.filter((l) => l.name === laboratory.name)?.length;
};
const convertPermissionsToLaboratories = (values: IPermission[]) => {
  let modules: IModule[] = [];
  let laboratoryName = "";
  const laboratories: ILaboratory[] = [];
  for (let i = 0; i < values?.length; i++) {
    const last = i === values?.length - 1;
    const equal = laboratoryName === values[i].laboratoryName;
    if (last && i === 0) {
      modules.push({ ...moduleInit, name: values[i].moduleName });
      laboratoryName = values[i].laboratoryName;
      laboratories.push({ ...laboratoryInit, name: laboratoryName, modules });
    } else if (i === 0) {
      modules.push({ ...moduleInit, name: values[i].moduleName });
      laboratoryName = values[i].laboratoryName;
    } else if (last && equal) {
      modules.push({ ...moduleInit, name: values[i].moduleName });
      laboratories.push({ ...laboratoryInit, name: laboratoryName, modules });
    } else if (last && !equal) {
      laboratories.push({ ...laboratoryInit, name: laboratoryName, modules });
      modules = [];
      modules.push({ ...moduleInit, name: values[i].moduleName });
      laboratoryName = values[i].laboratoryName;
      laboratories.push({ ...laboratoryInit, name: laboratoryName, modules });
    } else if (equal) {
      modules.push({ ...moduleInit, name: values[i].moduleName });
      laboratoryName = values[i].laboratoryName;
    } else if (!equal) {
      laboratories.push({ ...laboratoryInit, name: laboratoryName, modules });
      modules = [];
      modules.push({ ...moduleInit, name: values[i].moduleName });
      laboratoryName = values[i].laboratoryName;
    }
  }

  return laboratories;
};
const filterLaboratoriesInLaboratoriesLarger = (
  laboratoriesBig: ILaboratory[],
  selected: ILaboratory[],
) => {
  return laboratoriesBig?.filter((user) => !!searchLaboratoryInLaboratories(user, selected));
};
